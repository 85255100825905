import React from "react";

import Layout from "../../other/layout";
import SEO from "../../other/seo";

// import ADAinfo from "../../../components/AC-ADAbar/ADAinfo";
import ADAinfo from "../../components/AC-ADAbar/components/ADAinfo"

const infoADA = () => {
  return (
    <Layout>
      <SEO title="Accessibility" />
      <ADAinfo /> 
    </Layout>
  );
};

export default infoADA;



// import React from "react";

// import Layout from "../../other/layout";
// import SEO from "../../other/seo";

// import CookiePage from "../../components/AC-Cookie/CookiePage";

// const cookiePage = () => {
//   return (
//     <Layout>
//       <SEO title="Cookies" />
//       <CookiePage />
//     </Layout>
//   );
// };

// export default cookiePage;



