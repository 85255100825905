import React, { useContext } from "react";
// import "./styles/CookiePage.css"
import { Link } from "gatsby";
import { useState } from "react";
// import FTbtn from "./FTbtn"
import icon from "../../../assets/img/link-icon.png";
import { I18nContext } from "../../../i18n";
import classes from "../styles/ADAbar.module.css";

function ADAinfo() {
  return (
    <div className={classes.adaContPage}>
      <h1>Website Accessibility Policy</h1>
      <br></br>
      <p>
        Deluxe is committed to providing individuals with disabilities access to
        goods, services, and privileges offered on the website ShopDeluxe and is
        partaking in ongoing efforts to improve the accessibility of ShopDeluxe.
      </p>{" "}
      <br></br>
      <p>
        If you have specific questions or concerns about this site's
        accessibility or need assistance with using this site, please contact
        us. Please call 866-749-6327. Or{" "}
        <a href="mailto:cdncustserv@deluxe.ca?subject=Accessibility Support">
          cdncustserv@deluxe.ca
        </a>
        . When contacting us, please specify the particular web page about which
        you have a question, want to submit a comment, or require assistance.
      </p>
    </div>
  );
}

export default ADAinfo;
